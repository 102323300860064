import React from 'react';
import { Wrapper, Msg, Icon } from './Notification.css';

const Notification = ({ icon, msgText }) => {
  return (
    <Wrapper>
      <Icon>{icon}</Icon>
      <Msg>{msgText}</Msg>
    </Wrapper>
  );
};

export default Notification;
