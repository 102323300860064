import React from 'react';
import styled from 'styled-components/macro'
  ;
// import Section from 'templates/Section/Section'
// import BigWrapper from 'templates/Wrappers/BigWrapper'
import Switcher from 'assets/switcher-question.png';

const Container = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  /* position: relative; */
  @media screen and (min-width: 330px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  @media screen and (min-width: 992px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media screen and (min-width: 1200px) {
    max-width: 1050px;
  }

  @media screen and (min-width: 1400px) {
    max-width: 1160px;
  }
`;
const BigWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ImgsWrapper = styled.div`
  margin: 32px auto;
  padding: 16px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  @media screen and (min-width: 992px) {
    margin: 80px auto;
  }
`;
const ErrorIcon = styled.img`
  height: 100px;
  @media screen and (min-width: 992px) {
    height: 204px;
  }
  @media screen and (min-width: 1200px) {
    height: 308px;
  }
`;
const SwitcherIcon = styled.img`
  height: 114px;
  @media screen and (min-width: 992px) {
    height: 232px;
  }
  @media screen and (min-width: 1200px) {
    height: 361px;
  }
`;

const StyledH2 = styled.h2`
  width: 100%;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  display: block;
  font-weight: 400;

  @media screen and (min-width: 992px) {
    font-size: 32px;
    line-height: 40px;
  }
`;
const Bold = styled.span`
  font-weight: 800;
`;

const ButtonWrapper = styled.div`
  margin: 12px auto;
  @media screen and (min-width: 992px) {
    margin: 80px auto;
  }
`;

const Wrapper = styled.div`
  height: 100vh;
`;

const Service = () => {
  return (
    <>
      <Container>
        <BigWrapper>
          <Wrapper>
            {/* <MainLayoutWrapper bgColor="white" > */}
            <ImgsWrapper>
              <SwitcherIcon src={Switcher} alt="Switcher" />
            </ImgsWrapper>
            <StyledH2>
              <Bold>Trwają prace serwisowe </Bold>wkrótce będziesz miał dostęp
            </StyledH2>
          </Wrapper>
        </BigWrapper>
      </Container>
    </>
  );
};

export default Service;
