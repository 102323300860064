export const componentLoader = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        console.log(error, 'chunk err');
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          componentLoader(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};
