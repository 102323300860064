import styled from 'styled-components/macro'
  ;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;
export const Msg = styled.div`
  /* padding-bottom: 8px; */
`;

export const Icon = styled.div`
  margin-right: 8px;
  svg {
    fill: #ffffff;
  }
`;
