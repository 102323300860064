import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import TagManager from 'react-gtm-module';
import React from 'react';
import ReactDOM from 'react-dom';


import Root from './Root';
import * as serviceWorker from './serviceWorker';
import './i18n';

const GTM_KEY = process.env.REACT_APP_GTM;

const tagManagerArgs = {
  gtmId: GTM_KEY,
};
if (GTM_KEY) {
  TagManager.initialize(tagManagerArgs);
}


ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
